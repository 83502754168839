// use this reducer for UI and user activity state (not data from the backend)
import Cookies from 'cookies-js';
// @ts-ignore: missing type declaration file
import { CookieStorage } from 'redux-persist-cookie-storage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { AppState, AppActionTypes } from 'app/store/types';
import { getRootDomain } from 'app/utils/helpers';
import * as Actions from '../actions';

const initialState: AppState = {
	selectedLicenseGroupId: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupPageWrapper />
	queuesFilterText: '',
	usersFilterText: '',
	devicesFilterText: '',
	appsFilterText: '',
	rolesFilterText: '',
	logsFilterText: '',
	formsFilterText: '',
	workflowsFilterText: '',
	managedLicenseGroupsFilterText: '',
	logSection: '',
	logSubSection: '',
	logDateOption: '',
	logCustomDates: { start: null, end: null },
	logLevelOptions: [''],
	hideNavbar: false,
	cookieNagbarAcknowledged: false,
	cookieSettings: false,
	hideExpiredBanner: false,
	hideSuspendedBanner: false,
	hideExpiredScreen: false,
	siteBackgroundCache: 0,
	appIconCache: {},
	modifiedTenantPricing: {
		currentPrice: undefined,
		modifiedPrice: undefined
	}
};

const appReducer = (state = initialState, action: AppActionTypes) => {
	switch (action.type) {
		case Actions.GET_SELECTED_LICENSE_GROUP_ID_BY_SLUG: {
			return {
				...state,
				selectedLicenseGroupId: action.payload.selectedLicenseGroupId
			};
		}
		case Actions.SET_QUEUES_FILTER_TEXT: {
			return {
				...state,
				queuesFilterText: action.payload.queuesFilterText
			};
		}
		case Actions.SET_USERS_FILTER_TEXT: {
			return {
				...state,
				usersFilterText: action.payload.usersFilterText
			};
		}
		case Actions.SET_DEVICES_FILTER_TEXT: {
			return {
				...state,
				devicesFilterText: action.payload.devicesFilterText
			};
		}
		case Actions.SET_APPS_FILTER_TEXT: {
			return {
				...state,
				appsFilterText: action.payload.appsFilterText
			};
		}
		case Actions.SET_JOB_NAMES_FILTER_TEXT: {
			return {
				...state,
				jobNamesFilterText: action.payload.jobNamesFilterText
			};
		}
		case Actions.SET_ROLES_FILTER_TEXT: {
			return {
				...state,
				rolesFilterText: action.payload.rolesFilterText
			};
		}
		case Actions.SET_LOGS_FILTER_TEXT: {
			return {
				...state,
				logsFilterText: action.payload.logsFilterText
			};
		}
		case Actions.SET_FORMS_FILTER_TEXT: {
			return {
				...state,
				formsFilterText: action.payload.formsFilterText
			};
		}
		case Actions.SET_WORKFLOWS_FILTER_TEXT: {
			return {
				...state,
				workflowsFilterText: action.payload.workflowsFilterText
			};
		}
		case Actions.SET_MANAGED_LICENSE_GROUPS_FILTER_TEXT: {
			return {
				...state,
				managedLicenseGroupsFilterText: action.payload.managedLicenseGroupsFilterText
			};
		}
		case Actions.SET_LOG_SECTION: {
			return {
				...state,
				logSection: action.payload.logSection
			};
		}
		case Actions.SET_LOG_SUB_SECTION: {
			return {
				...state,
				logSubSection: action.payload.logSubSection
			};
		}
		case Actions.SET_LOG_DATE_OPTION: {
			return {
				...state,
				logDateOption: action.payload.logDateOption
			};
		}
		case Actions.SET_LOG_CUSTOM_DATES: {
			return {
				...state,
				logCustomDates: action.payload.logCustomDates
			};
		}
		case Actions.SET_LOG_LEVEL_OPTIONS: {
			return {
				...state,
				logLevelOptions: action.payload.logLevelOptions
			};
		}
		case Actions.HIDE_NAVBAR: {
			return {
				...state,
				hideNavbar: action.payload.hide
			};
		}
		case Actions.ACKNOWLEDGE_COOKIE_NAGBAR: {
			return {
				...state,
				cookieNagbarAcknowledged: true
			};
		}
		case Actions.SET_COOKIE_SETTINGS: {
			return {
				...state,
				cookieSettings: action.payload.cookieSettings
			};
		}
		case Actions.HIDE_EXPIRED_BANNER: {
			return {
				...state,
				hideExpiredBanner: true
			};
		}
		case Actions.HIDE_SUSPENDED_BANNER: {
			return {
				...state,
				hideSuspendedBanner: true
			};
		}
		case Actions.HIDE_EXPIRED_SCREEN: {
			return {
				...state,
				hideExpiredScreen: true
			};
		}
		case Actions.BUMP_SITE_BACKGROUND_CACHE: {
			return {
				...state,
				siteBackgroundCache: state.siteBackgroundCache + 1
			};
		}
		case Actions.BUMP_APP_ICON_CACHE: {
			return {
				...state,
				appIconCache: {
					...state.appIconCache,
					[action.payload.appId]: (state.appIconCache[action.payload.appId] ?? 0) + 1
				}
			};
		}
		// wipe selectedLicenseGroupId if id not found in returned license groups list
		// case Actions.GET_ADMINED_LICENSE_GROUPS_SUCCESS: {
		// 	const prevSelectedLicenseGroupId = state.selectedLicenseGroupId as string | undefined; // HACK::simplifying type by just being string but it can be both here

		// 	return {
		// 		...state,
		// 		selectedLicenseGroupId:
		// 			prevSelectedLicenseGroupId && action.payload.data?.[prevSelectedLicenseGroupId]
		// 				? prevSelectedLicenseGroupId
		// 				: undefined!
		// 	};
		// }
		case Actions.GET_MODIFIED_TENANT_PRICING_SUCCESS: {
			const { currentPrice, modifiedPrice } = action.payload;

			return {
				...state,
				modifiedTenantPricing: {
					currentPrice,
					modifiedPrice
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
			return {
				...initialState,
				cookieNagbarAcknowledged: state.cookieNagbarAcknowledged,
				hideNavbar: state.hideNavbar
			};
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

const cookieStorageOptions = {
	expiration: {
		default: 2147483647
	},
	setCookieOptions: {
		// DEV NOTE::cookies will not work for localhost (_could_ probably set up a HOST domain for this to work locally?)
		domain: process.env.NODE_ENV === 'development' ? undefined : getRootDomain()
	}
};

export default persistReducer(
	{
		key: 'app',
		storage: new CookieStorage(Cookies, cookieStorageOptions),
		whitelist: ['cookieNagbarAcknowledged', 'siteBackgroundCache', 'appIconCache'] // wipe most app state on refresh
	},
	appReducer
);

// Selectors
export const getSelectedLicenseGroupId = ({ app }: RootState) => app.selectedLicenseGroupId;
export const getQueuesFilterText = ({ app }: RootState) => app.queuesFilterText;
export const getUsersFilterText = ({ app }: RootState) => app.usersFilterText;
export const getDevicesFilterText = ({ app }: RootState) => app.devicesFilterText;
export const getAppsFilterText = ({ app }: RootState) => app.appsFilterText;
export const getRolesFilterText = ({ app }: RootState) => app.rolesFilterText;
export const getLogsFilterText = ({ app }: RootState) => app.logsFilterText;
export const getFormsFilterText = ({ app }: RootState) => app.formsFilterText;
export const getWorkflowsFilterText = ({ app }: RootState) => app.workflowsFilterText;
export const getManagedLicenseGroupsFilterText = ({ app }: RootState) => app.managedLicenseGroupsFilterText;
export const getLogSection = ({ app }: RootState) => app.logSection;
export const getLogSubSection = ({ app }: RootState) => app.logSubSection;
export const getLogDateOption = ({ app }: RootState) => app.logDateOption;
export const getLogCustomDates = ({ app }: RootState) => app.logCustomDates;
export const getLogLevelOptions = ({ app }: RootState) => app.logLevelOptions;
export const getHideNavbar = ({ app }: RootState) => app.hideNavbar;
export const getCookieNagbarAcknowledged = ({ app }: RootState) => app.cookieNagbarAcknowledged;
export const getCookieSettings = ({ app }: RootState) => app.cookieSettings;
export const getHideExpiredBanner = ({ app }: RootState) => app.hideExpiredBanner;
export const getHideSuspendedBanner = ({ app }: RootState) => app.hideSuspendedBanner;
export const getHideExpiredScreen = ({ app }: RootState) => app.hideExpiredScreen;
export const getSiteBackgroundCache = ({ app }: RootState) => app.siteBackgroundCache;
export const getAppIconCache = ({ app }: RootState) => app.appIconCache;
export const getModifiedTenantPricing = ({ app }: RootState) => app.modifiedTenantPricing;
