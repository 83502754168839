import _ from '@lodash';
import React from 'react';
import i18next from 'i18next';
import i18n from 'i18n';
import * as yup from 'yup';
import { format, parse } from 'date-fns';
import {
	App,
	InboxItem,
	JobItem,
	JobQueue,
	LicenseGroupData,
	Log,
	PendingUser,
	Profile,
	Role,
	User
} from 'app/store/types';
import { Translation, useTranslation } from 'react-i18next';
import { getPlanDomain } from 'app/utils/tenant-plan';

export const DEFAULT_WORKFLOWS_GROUP_ID = '10000001100110011001100000000001'; // '11111111111111111111111111111111'; // 10000001-1001-1001-1001-100000000001
export const SAMPLE_WORKFLOWS_GROUP_ID = '01111110011001100110011111111110'; // '11111111111111111111111111111111'; // 10000001-1001-1001-1001-100000000001
export const SAMPLE_FORMS_GROUP_ID = '00011110011001100110011111111110';

export const SAMPLE_WORKFLOWS_GROUP = {
	id: SAMPLE_WORKFLOWS_GROUP_ID,
	name: i18n.t('Sample Workflows'),
	groupOrder: undefined,
	collapse: undefined,
	dateUpdated: Date.now(),
	acl: {
		users: [],
		groups: [],
		devices: [],
		deviceGroups: []
	}
};

export const SAMPLE_FORMS_GROUP = {
	id: SAMPLE_FORMS_GROUP_ID,
	title: i18n.t('Sample Forms'),
	groupOrder: undefined,
	collapse: undefined,
	dateUpdated: Date.now()
} as const;

// TODO::switch to `react-time-ago` component (need to solve importing locales)
export const daysAgo = (date: number) => {
	const numberOfDaysAgo = Math.round((new Date().getTime() - date) / (1000 * 60 * 60 * 24));
	switch (numberOfDaysAgo) {
		case 0:
			return <Translation>{t => t('dashboard:today')}</Translation>;
		case 1:
			return <Translation>{t => t('dashboard:yesterday')}</Translation>;
		default:
			return <Translation>{t => t('dashboard:days ago', { numberOfDaysAgo })}</Translation>;
	}
};

export const isValidUrl = (value: string) =>
	yup
		.string()
		.url()
		.required()
		.isValidSync(value);

export const isValidEmail = (value: string) =>
	yup
		.string()
		.matches(/^[a-zA-Z0-9](?!.*([._%+-])\1)[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
		.required()
		.max(254)
		.isValidSync(value);

// test for special characters and whitespace
export const isValidName = (value: string) => {
	if (noSpecialCharacters(value) && !isOnlyWhitespace(value)) {
		return true;
	}
	return false;
};

export const isValidPhoneNumber = (value: string) => /^[0-9.-]*$/.test(value);
// FIXME::Regex should be /^([0-9]+-?)*$/

export const isValidSlug = (value: string) => /^[a-zA-Z0-9]+([a-zA-Z0-9-]+[a-zA-Z0-9]+)?$/.test(value);

export const isOnlyWhitespace = (value: string) => value.match(/^ *$/) !== null;
export const noSpecialCharacters = (value: string) => !/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(value);

export const getSubdomainsInReverseOrder = (url: string) => {
	url = url.replace(/(localhost|\[::1\]|127(?:\.[0-9]+){0,2}\.[0-9]+)(:\d+)?$/, 'localhost.com'); // fake "normal" localhost URL for easier parsing

	const { hostname } = new URL(url);

	const parts = hostname.split('.');

	const subdomainsInReverseOrder = parts.slice(0, -2).reverse();

	// treat `www` off the root as a fake subdomain
	if (subdomainsInReverseOrder[0] === 'www') {
		subdomainsInReverseOrder.shift();
	}

	return subdomainsInReverseOrder;
};

export const isRootUrl = (url: string) => getSubdomainsInReverseOrder(url).length === 0;
export const isLicenseGroupUrl = (url: string) => {
	const subdomains = getSubdomainsInReverseOrder(url);
	return subdomains.length === 2 && subdomains[0] === 'tenant';
};

export const getRootDomain = (staticDomain = false) => {
	// DEV NOTE::when we first built this project we only deployed the services to one region,...
	// ...and _which_ region it happened to be was different per environment
	// HACK-ish::TEMP::to my knowledge we _will_ eventually just deploy these services to both regions
	if (staticDomain) {
		return getPlanDomain(process.env.REACT_APP_ENVIRONMENT === 'production' ? 'stc' : 'stratus');
	}

	const url = window.location.host;

	return url
		.split('.')
		.reverse()
		.slice(0, url.match(/(localhost|\[::1\]|127(?:\.[0-9]+){0,2}\.[0-9]+)(:\d+)?$/) ? 1 : 2)
		.reverse()
		.join('.');
};

export const sanitizeFilename = (name: string) => name.replace(/[^\p{L}\p{N}\s_-]/gu, '');

// export const getGenerationByFamily = (family: string): Device['generation'] => {
// 	// only use beginning of family name (for some reason it seems the family sometimes has a longer name)
// 	switch (family.split('_')[0]) {
// 		case 'Kronos':
// 		case 'Venus':
// 			return 'A4';
// 		case 'Amur':
// 		case 'Donau':
// 		case 'DonauBK':
// 		case 'Taiga':
// 			return 'IT3';
// 		case 'KronosS':
// 		case 'Poseidon':
// 		case 'ZeusS':
// 		case 'Helios':
// 		case 'MinervaSBK':
// 		case 'ZeusSZX0':
// 		case 'ZeusSBK':
// 		case 'VenusMLK':
// 			return 'IT5';
// 		case 'Altair':
// 		case 'Cypress':
// 		case 'DenebMLK':
// 		case 'EagleBKH':
// 		case 'EagleBKL':
// 		case 'EagleL':
// 		case 'EagleH':
// 		case 'EagleZPlus':
// 		case 'HeliosMLK':
// 		case 'Maple':
// 		case 'Rosewood':
// 		case 'Sparrow':
// 		case 'SparrowBK':
// 			return 'IT6';
// 		case 'WHUB':
// 			return 'workplacehub';
// 		default:
// 			return 'IT4';
// 	}
// };

export const arrayify = <T,>(value: T | T[]): T[] => (Array.isArray(value) ? value : [value]);

// regular display: "11/2/2017, 12:13:42 PM"
// friendlyDisplay: "November 2, 2017"

export const getLocalTime = (
	date: ConstructorParameters<typeof Date>[0],
	friendlyDisplay = false,
	timezoneOffset?: number | null // expected to be in minutes
) => {
	if (date === undefined || date === null) {
		return undefined;
	}

	let localDate: Date;

	if (typeof timezoneOffset === 'number') {
		const timestamp = typeof date === 'number' ? date : new Date(date).getTime();
		const localTimestamp = timestamp + timezoneOffset * 60000; // apply offset, convert to milliseconds
		localDate = new Date(localTimestamp);
	} else {
		localDate = new Date(date);
	}

	let formattedDate: string;
	if (friendlyDisplay) {
		const year = localDate.getUTCFullYear();
		const month = localDate.getUTCMonth() + 1;
		const day = localDate.getUTCDate();

		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			timeZone: 'UTC'
		};

		formattedDate = new Date(Date.UTC(year, month - 1, day)).toLocaleDateString(i18next.language, options);
	} else {
		const options: Intl.DateTimeFormatOptions = {
			timeZone: 'UTC'
		};
		formattedDate = localDate.toLocaleString(i18next.language, options);
	}
	return formattedDate;
};

export const convertLocalToUTCHack = (date: Date) => {
	return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const convertUTCHacktoLocal = (date: Date) => {
	const now = new Date();
	return new Date(date.getTime() - now.getTimezoneOffset() * 60000);
};

const deepOmitNullish = (obj: Record<string, any>): Record<string, any> =>
	Object.entries(obj)
		.filter(([_key, value]) => !_.isNil(value))
		.reduce(
			(acc, [key, value]) => ({
				...acc,
				[key]: _.isObject(value) ? deepOmitNullish(value) : value
			}),
			{}
		);

export const isEqualOmitNullish = (a: Record<string, any>, b: Record<string, any>) =>
	_.isEqual(deepOmitNullish(a), deepOmitNullish(b));

export const toHHMM = (time: string) => {
	let [HH, MM] = time.split(':');
	if (null || undefined) {
		return '';
	}
	if (Number(HH) < 10 && HH[0] !== '0') {
		HH = `0${HH}`;
	} else if (Number(HH) === 0) {
		HH = '00';
	}
	if (Number(MM) < 10 && MM[0] !== '0') {
		MM = `0${MM}`;
	} else if (Number(MM) === 0) {
		MM = '00';
	}

	return `${HH}:${MM}`;
};

// FIXME::need to combine `responseError` and `responseErrors` or just...make this less nonsense
type ResponseErrorsTypeA = { data: { returnCode: string }[] }[];
type ResponseErrorsTypeB = { data: { errors?: { add?: string[]; delete?: string[] } } }[];
const isResponseErrorsTypeA = (
	responses: ResponseErrorsTypeA | ResponseErrorsTypeB | any
): responses is ResponseErrorsTypeA => !!responses?.[0]?.data?.[0]?.returnCode;
const isResponseErrorsTypeB = (
	responses: ResponseErrorsTypeA | ResponseErrorsTypeB | any
): responses is ResponseErrorsTypeB => {
	const errors = responses?.[0]?.data?.errors;
	return !!(errors?.add || errors?.delete);
};
export const responseErrors = (responses: ResponseErrorsTypeA | ResponseErrorsTypeB | unknown) => {
	responses = arrayify(responses); // HACK-ish::just turn into an array if not one (this may need to be smartened up) - we can get away with this due to the `unknown` type
	if (isResponseErrorsTypeA(responses)) {
		const errors = responses
			.map(({ data }) => data)
			.flat()
			.filter(({ returnCode }) => `${returnCode}`[0] !== '2');
		if (errors.length) console.error(errors);
		return errors;
	}
	if (isResponseErrorsTypeB(responses)) {
		const errors = responses
			.map(({ data }) => (data.errors ? [...(data.errors.add || []), ...(data.errors.delete || [])] : []))
			.flat();
		if (errors.length) console.error(errors);
		return errors;
	}
	return [];
};

type ErrorOutput = {
	payload: {
		error: string;
		message: string;
		statusCode: number;
	};
	statusCode: number;
};
type ResponseError = { output: ErrorOutput }[] | null;
export const responseError = ({ errors }: { errors: ResponseError }) => {
	const error = errors?.[0]?.output;
	console.error(error);
	return error;
};

export const TLog = ({ log: { messageKey, info } }: { log: Pick<Log, 'messageKey' | 'info'> }) => {
	const { t: tL } = useTranslation('log');

	// if (messageKey.startsWith('taskStatus')) {
	// 	messageKey = `taskStatus${info!.status!.toUpperCase()}`;
	// 	// @ts-ignore
	// 	info = { ...info, task: info.task?.toLowerCase() };
	// }

	return <>{tL(messageKey, info)}</>;
};

const DEMO_FILE_COUNT_MAX = 50;
const DEMO_FILE_SIZE_MAX = 10485760; // 10MB (10,485,760 bytes)

export const getLicenseCapacityExceeded = (licenseGroupData: LicenseGroupData) => {
	const { licenseUsage, orderType } = licenseGroupData;
	if (!licenseUsage || orderType !== 'DEMO') {
		return false;
	}

	// Get 0:00AM on based on the browser timezone
	const today = new Date().setHours(0, 0, 0, 0);

	const isLicenseCapacityExceeded = !!(
		licenseUsage?.dateUpdated &&
		licenseUsage?.dateUpdated > today &&
		(licenseUsage?.fileCount > DEMO_FILE_COUNT_MAX || licenseUsage?.fileSizeTotal > DEMO_FILE_SIZE_MAX)
	);

	return isLicenseCapacityExceeded;
};

export const EXPIRED_GRACE_PERIOD: Record<LicenseGroupData['orderType'], number> = {
	PRODUCTION: 30 * (24 * 3600 * 1000),
	DEMO: 7 * (24 * 3600 * 1000),
	NFR: 30 * (24 * 3600 * 1000)
};

export const convertToFormData = (obj: { [name: string]: string | Blob }) => {
	const formData = new FormData();
	Object.entries(obj).forEach(([name, value]) => formData.append(name, value));
	return formData;
};

export const getSiteBackgroundImageWithFallbacks = ({
	licenseGroupId,
	profileId,
	siteBackgroundCache,
	appearance = 'light'
}: {
	licenseGroupId: string;
	profileId: string;
	siteBackgroundCache: number;
	appearance?: 'light' | 'dark';
}) => [
	`/api/user/${licenseGroupId}/preferences/background?key=${profileId}&v=${siteBackgroundCache}`,
	`/api/user/${licenseGroupId}/preferences/background?key=${profileId}&v=${siteBackgroundCache - 1}`,
	appearance === 'light'
		? `${process.env.PUBLIC_URL}/assets/images/featured-backgrounds/colors/light-gray.svg`
		: `${process.env.PUBLIC_URL}/assets/images/featured-backgrounds/colors/dark-gray.svg`
];

export const reviewAppSettings = () => {
	let formBuilderService = 'formbuilder';
	let formsService = 'forms-service';
	let wfxService = 'wfx';
	let metadataService = 'metadata-api';
	let loggerService = 'logger';
	let nodeTestingService = 'ntf';

	if (localStorage) {
		const settingsJson = localStorage.getItem('sec.review.portal.settings');
		if (settingsJson) {
			const settings = JSON.parse(settingsJson);
			if (settings?.formBuilderService) formBuilderService = settings.formBuilderService;
			if (settings?.formsService) formsService = settings.formsService;
			if (settings?.wfxService) wfxService = settings.wfxService;
			if (settings?.metadataService) metadataService = settings.metadataService;
			if (settings?.loggerService) loggerService = settings.loggerService;
			if (settings?.nodeTestingService) nodeTestingService = settings.nodeTestingService;
		}
	}

	return {
		formBuilderService,
		formsService,
		metadataService,
		wfxService,
		loggerService,
		nodeTestingService
	} as const;
};

type Path = string | ConstructorParameters<typeof URLSearchParams>[0];

// accepts either a full path string or an object which is converted to a query string
const makePath = (path: Path) => (typeof path === 'string' ? path : `?${new URLSearchParams(path).toString()}`);

export const getWfxDesignerUrl = (path: { plan: LicenseGroupData['plan']; [key: string]: string }) => {
	const slug = window.location.host.match(/^(.+)\.tenant\./)?.[1];
	return `web+kmdesigner://${slug}.tenant.${getRootDomain(true)}${makePath({
		...path,
		planDomain: getPlanDomain(path.plan)
	})}`;
};

export const getHelpUrl = (locale: string, path: Path = '') => {
	return `https://help.${getRootDomain()}?locale=${locale.slice(0, 2)}&path=${encodeURIComponent(makePath(path))}`;
};

type Region = Profile['awsRegion'];

export const getMetadataUrl = (region: Region = 'us-east-1') =>
	`https://${region}.${reviewAppSettings().metadataService}.${getRootDomain(true)}`;

export const getWfxUrl = (region: Region = 'us-east-1') =>
	`https://${region}.${reviewAppSettings().wfxService}.${getRootDomain(true)}`;

export const getFormsUrl = (region: Region = 'us-east-1') =>
	`https://${region}.${reviewAppSettings().formsService}.${getRootDomain(true)}`;

export const getFormBuilderUrl = () => `https://${reviewAppSettings().formBuilderService}.${getRootDomain()}`;

export const getLogApiUrl = (region: Region = 'us-east-1') =>
	`https://${region}.${reviewAppSettings().loggerService}.${getRootDomain(true)}/v1`;

export const getNodeTestingAPI = (region: Region = 'us-east-1') =>
	`https://${region}.${reviewAppSettings().nodeTestingService}.${getRootDomain(true)}`;

export const getApproveNodesUrl = (region: Region = 'us-east-1', nameSpace: string) => {
	return nameSpace.includes('QueueEditAndApprove')
		? `https://${region}.people-queue-edit.${getRootDomain(true)}`
		: `https://${region}.p-edit-approve.${getRootDomain(true)}`;
};

export const getPrivacyPolicyUrl = (region: Region = 'us-east-1') =>
	region === 'ca-central-1'
		? 'https://konicaminolta.ca/en/business/siteinformation/terms-of-use'
		: 'https://kmbs.konicaminolta.us/privacy-policy';

const checkDiff = (role: any, keys: any) => {
	if (typeof role === 'boolean') {
		return !role;
	}

	for (const key of keys) {
		if (!checkDiff(role[key], Object.keys(role[key]))) {
			return false;
		}
	}
	return true;
};

const getKeys = (userPermission: any, targetPermissions: any, keys: any) => {
	if (typeof userPermission === 'boolean' && typeof targetPermissions === 'boolean') {
		return userPermission || (!userPermission && !targetPermissions);
	}
	for (const key of keys) {
		const keys1 = Object.keys(userPermission[key]);
		const keys2 = Object.keys(targetPermissions[key]);
		const diff = _.difference(keys2, keys1);
		const same = _.difference(keys2, diff);
		if (diff.length && !checkDiff(targetPermissions[key], diff)) {
			return false;
		}
		if (!getKeys(userPermission[key], targetPermissions[key], same)) {
			return false;
		}
	}
	return true;
};

export const canAssignRole = (userPermission: any, targetPermissions: any): boolean => {
	syncObjectStructure(userPermission, defaultPermissions);
	syncObjectStructure(targetPermissions, defaultPermissions);
	const keys1 = Object.keys(userPermission);
	const keys2 = Object.keys(targetPermissions);
	const diff = _.difference(keys2, keys1);
	const same = _.difference(keys2, diff);
	if (diff.length && !checkDiff(targetPermissions, diff)) {
		return false;
	}
	return getKeys(userPermission, targetPermissions, same);
};

const syncObjectStructure = (object: any, template: any) => {
	(function addFromTemplate(obj, temp) {
		for (const prop in temp) {
			if (!(prop in obj)) obj[prop] = temp[prop];
			// Unknown to `obj`! Add it
			else if (typeof obj[prop] === 'object' && typeof temp[prop] === 'object')
				addFromTemplate(obj[prop], temp[prop]); // Nested objects! Recursion-step
		}
	})(object, template); // Start adding to `object` from `template`

	(function removeFromObject(obj, temp) {
		for (const prop in obj) {
			if (!(prop in temp)) delete obj[prop];
			// Unknown to `temp`! Remove it
			else if (typeof obj[prop] === 'object' && typeof temp[prop] === 'object')
				removeFromObject(obj[prop], temp[prop]); // Nested objects! Recursion-step
		}
	})(object, template); // Start removing properties of `object`
};

export const formatBytes = (bytes: number, decimals = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getAppLink = (app: Pick<App, 'type' | 'workflowId' | 'nodeId' | 'url'>, tenant: LicenseGroupData) => {
	switch (app.type) {
		case 'url':
			return app.url ?? '';
		case 'internal':
			return `https://${tenant.slug}.tenant.${getRootDomain()}/admin/internalform/${tenant.region}/${
				app.workflowId
			}-${app.nodeId}`;
		case 'external':
			return app.url
				? app.url
				: `https://forms.${getRootDomain()}/externalform/${tenant.region}/${tenant.slug}/${app.workflowId}-${
						app.nodeId
				  }`;
		default:
			return '';
	}
};

export const defaultPermissions = {
	devicesTab: {
		devicesSection: {
			view: false,
			add: false,
			edit: false,
			remove: false,
			activate: false,
			createGroup: false,
			editGroup: false,
			deleteGroup: false
		},
		inputNodesSection: {
			view: false,
			viewAll: false,
			assign: false,
			assignAll: false
		}
	},
	formsTab: {
		formsSection: {
			view: false,
			add: false,
			edit: false,
			remove: false,
			import: false,
			activate: false,
			deactivate: false,
			createGroup: false,
			editGroup: false,
			deleteGroup: false,
			clone: false
		}
	},
	usersTab: {
		usersSection: {
			view: false,
			invite: false,
			edit: false,
			remove: false,
			assignUser: false,
			block: false,
			createGroup: false,
			editGroup: false,
			deleteGroup: false,
			assignGroup: false,
			transferOwnership: false,
			resetPassword: false
		}
	},
	appsTab: {
		internalSection: {
			create: false,
			view: false,
			delete: false,
			share: false
		},
		externalSection: {
			create: false,
			view: false,
			delete: false,
			share: false
		},
		urlSection: {
			create: false,
			view: false,
			delete: false,
			share: false
		}
	},
	workflowsTab: {
		workflowsSection: {
			view: false,
			create: false,
			edit: false,
			remove: false,
			share: false,
			import: false,
			run: false,
			changeOwner: false,
			createGroup: false,
			shareGroup: false,
			editGroup: false,
			deleteGroup: false,
			clone: false,
			samples: false
		}
	},
	dataTab: {
		reportsSection: {
			view: false
		},
		logsSection: {
			view: false
		}
	},
	jobsTab: {
		jobQueueSection: {
			view: false,
			create: false,
			edit: false,
			remove: false,
			activate: false
		},
		trackerSection: {
			view: false,
			track: false
		},
		tasksSection: {
			view: false
		}
	},
	rolesTab: {
		rolesSection: {
			view: false,
			create: false,
			edit: false,
			remove: false
		}
	},
	settingsTab: {
		settingsSection: {
			view: false,
			edit: false
		},
		profileSection: {
			outOfOffice: false
		}
	}
};

export const errorCodes = {
	fileUploadFail: '10100',
	fileUploadFailOnRead: '10101',
	fileUploadFailOnWrite: '10102',
	fileUploadFailAuth: '10103',
	fileUploadFailOnConflict: '10104',
	fileUploadFailOnForbidden: '10105',
	fileUploadFailOnMissing: '10106',
	fileUploadFailOnCreateFolder: '10107',
	fileUploadFailOutOfSpace: '10108',
	fileUploadFailSizeLimit: '10109',
	fileUploadFailPathIsTooLong: '10110',
	fileUploadFailOnInsufficientStorage: '10111',
	fileUploadNodeDisabled: '10112',
	messageSendValidationFail: '10200',
	messageSendExceedLimit: '10201',
	messageSendFail: '10202',
	generalError: '00100',
	wfxUpdateStatusFailure: '10300',
	userErrorAlreadyAssigned: '20100',
	userErrorFailedInvite: '20102',
	userErrorPartial: '20103',
	userErrorUnexpected: '20104',
	userErrorNotFound: '20105',
	userErrorNoEmail: '20106',
	userErrorInvalidStatus: '20107',
	userErrorFailedReinvite: '20108',
	userErrorBlockUnauthorized: '20109',
	userErrorAlreadyBlocked: '20110',
	userErrorBlockPendingUser: '20111',
	userErrorBlockLastAdmin: '20112',
	userErrorBlockFailed: '20113',
	userErrorBlockUnexpected: '20114',
	userErrorUnblockUnauthorized: '20115',
	userErrorAlreadyUnblocked: '20116',
	userErrorUnblockFailed: '20117',
	userErrorUnblockUnexpected: '20118',
	deviceErrorNoSuchDevice: '30100',
	deviceErrorDeleteFailure: '30101',
	deviceErrorDeletePartial: '30102',
	deviceErrorDeleteUnexpected: '30103',
	deviceErrorAddUnauthorized: '30104',
	deviceErrorAddFailed: '30105',
	deviceErrorUnauthorized: '30106',
	deviceErrorUnsupported: '30107',
	deviceErrorAlreadyAssoc: '30108',
	deviceErrorAddPartial: '30109',
	deviceErrorAddUnexpected: '30110',
	deviceErrorValidation: '30111',
	deviceErrorLicenseFail: '30112',
	deviceErrorNoLicense: '30113',
	deviceErrorBadSchema: '30114',
	deviceErrorRemoveLicenseFail: '30115',
	appErrorNoSuchApp: '40100',
	appErrorDeleteFailure: '40101',
	appErrorDeletePartial: '40102',
	appErrorDeleteUnexpected: '40103',
	appErrorAddUnauthorized: '40104',
	appErrorAddFailed: '40105',
	appErrorUnauthorized: '40106',
	appErrorUnsupported: '40107',
	appErrorAlreadyAssoc: '40108',
	appErrorAddPartial: '40109',
	appErrorAddUnexpected: '40110',
	appErrorValidation: '40111',
	appErrorLicenseFail: '40112',
	appErrorNoLicense: '40113',
	appErrorBadSchema: '40114',
	appErrorRemoveLicenseFail: '40115',
	nodeDisabled: '10300'
} as const;

export function groupByID<T extends JobItem | InboxItem | JobQueue>(rawItems: T[]): { [id: string]: T } {
	const itemsByID: { [id: string]: T } = {};
	if (!rawItems || rawItems.length === 0) return itemsByID;
	rawItems.forEach(item => {
		itemsByID[item.id] = item;
	});
	return itemsByID;
}

export const getSuperAdminRole = (roles: Role[]) => {
	return roles.find(role => role?.systemRole === 'tenant-super-admin')!.id;
};

export const getSuperAdmins = (users: (User | PendingUser)[], roles: Role[], activeAdminsOnly = false) => {
	const superAdminRole = getSuperAdminRole(roles);
	return users.filter(
		user => !(activeAdminsOnly && (user.blocked || user.pending)) && user.roles.includes(superAdminRole)
	);
};

export const formatDate = (date?: Date, fmt = 'P, pp') => {
	// const formatter = formatWithOptions({ locale: getLocale(i18next.language) }, format);
	// return formatter(date ?? new Date());
	return format(date ?? new Date(), fmt);
};

export const parseDate = (dateString: string, fmt = 'P, pp') => {
	const format = !_.isEmpty(fmt) ? fmt : 'P, pp';
	return parse(dateString || formatDate(new Date()), format, new Date());
};

export const getUserNameFormatted = (user: User) => {
	return `${user?.lastName}, ${user?.firstName}`;
};

export const massageTransferInfo = (rawTransferInfo: any) => {
	const transferInfo: {
		temporary: {
			redirect: boolean;
			startDate: string;
			endDate: string;
			sendEmail: boolean;
			active: boolean;
			allowLogin: boolean;
			toUserId: string;
			message?: string;
		};
		permanent: {
			startDate: string;
			sendEmail: boolean;
			transfer: boolean;
			active: boolean;
			removeUser: boolean;
			toUserId: string;
			immediate: boolean;
		};
	} = {
		temporary: {
			redirect: rawTransferInfo?.temporary?.redirect,
			startDate: rawTransferInfo?.temporary?.schedule?.startDate
				? formatDate(
						convertUTCHacktoLocal(parseDate(rawTransferInfo?.temporary?.schedule?.startDate, 'yyyyMMddHH'))
				  )
				: '',
			endDate: rawTransferInfo?.temporary?.schedule?.endDate
				? formatDate(
						convertUTCHacktoLocal(parseDate(rawTransferInfo?.temporary?.schedule?.endDate, 'yyyyMMddHH'))
				  )
				: '',
			sendEmail: rawTransferInfo?.temporary?.sendEmail,
			active: rawTransferInfo?.temporary?.active,
			allowLogin: rawTransferInfo?.temporary?.allowLogin,
			toUserId: rawTransferInfo?.temporary?.toPersonId,
			message: rawTransferInfo?.temporary?.message
		},
		permanent: {
			startDate: rawTransferInfo?.permanent?.schedule?.startDate
				? formatDate(
						convertUTCHacktoLocal(parseDate(rawTransferInfo?.permanent?.schedule?.startDate, 'yyyyMMddHH'))
				  )
				: '',
			sendEmail: rawTransferInfo?.permanent?.sendEmail,
			transfer: rawTransferInfo?.permanent?.transfer,
			active: rawTransferInfo?.permanent?.active,
			removeUser: rawTransferInfo?.permanent?.removeUser,
			toUserId: rawTransferInfo?.permanent?.toPersonId,
			immediate: rawTransferInfo?.permanent?.immediate
		}
	};

	return transferInfo;
};
